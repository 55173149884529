import * as React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const Seo = ({ title, description, keywords, pathname, children }) => {
    const { title: defaultTitle, description: defaultDescription, keywords: defaultKeywords, siteUrl } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || defaultKeywords,
        url: `${siteUrl}${pathname || ``}`,
    }

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}></meta>
            <meta keywords="keywords" content={seo.keywords}></meta>
            <meta name="twitter:title" content={seo.title}></meta>
            <meta name="twitter:url" content={seo.url}></meta>
            <meta name="twitter:description" content={seo.description}></meta>
            {children}
        </>
    )
}